import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import {
  PencilIcon,
  TrashIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  RocketLaunchIcon,
  CalendarDaysIcon
} from "@heroicons/react/24/outline";
import { TertiaryButton, TertiaryLink } from "../buttons/buttons";
import "./table.scss";

interface TablePropsType {
  columns: ColumnDef<any>[];
  data: any[];
  cellStyle?: string;
  tableStyle?: string;
  actions?: actionsType;
}
export interface actionsType {
  update?: (row: any) => void;
  delete?: (row: any) => void;
  select?: (row: any) => void;
  visit?: (row: any) => void;
  checkSub?: (row: any) => void;
}
export default function Table({
  columns,
  data,
  cellStyle,
  tableStyle,
  actions,
}: TablePropsType) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });
  return (
    <table className={tableStyle}>
      <thead className="border-b-2 border-zinc-500">
        {table.getHeaderGroups().map((headerGroup, index) => (
          <tr key={headerGroup.id + index}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={cellStyle}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        (header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "") + " text-zinc-700 text-md font-semibold"
                      }
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Croissant"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Décroissant"
                            : "Rétablir"
                          : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div>
                        {(header.column.getIsSorted() as string) === "asc" ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (header.column.getIsSorted() as string) ===
                          "desc" ? (
                          <ChevronDownIcon className="w-5 h-5" />
                        ) : null}
                      </div>
                    </div>
                  )}
                </th>
              );
            })}
            <th className="text-zinc-700 text-md font-semibold">Actions</th>
          </tr>
        ))}
      </thead>
      <tbody>
        {table
          .getRowModel()
          .rows.slice(0.1)
          .map((row, index) => {
            return (
              <>
                <tr
                  key={index}
                  className={
                    (row.original.collectionMother ? "text-indigo-700" : "") +
                    " border-b border-zinc-300" + (row.original.disabled ? " disabled" : "")
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={cellStyle}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                  <td>
                    <div className="flex items-center gap-x-2">
                      {actions?.update && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          onClick={() =>
                            actions.update ? actions.update(row.original) : {}
                          }
                          icon={<PencilIcon className="h-4 w-4" />}
                        />
                      )}
                      {actions?.delete && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          onClick={() =>
                            actions.delete ? actions.delete(row.original) : {}
                          }
                          icon={<TrashIcon className="h-4 w-4" />}
                        />
                      )}
                      {actions?.visit && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          onClick={() =>
                            actions.visit ? actions.visit(row.original) : {}
                          }
                          icon={<RocketLaunchIcon className="h-4 w-4" />}
                        />
                      )}
                      {actions?.checkSub && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          onClick={() =>
                            actions.checkSub ? actions.checkSub(row.original) : {}
                          }
                          icon={<CalendarDaysIcon className="h-4 w-4" />}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
      </tbody>
    </table>
  );
}
