import { BaseService } from "./base.service";
import * as XLSX from 'xlsx';
import {collectionType, CollectionXLSX } from "../models/collection.model";

export default class CollectionsParserService extends BaseService {
  constructor() {
    super();
  }
  readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as ArrayBuffer);
      fileReader.onerror = () => reject(new Error('Failed to read file'));
      fileReader.readAsArrayBuffer(file);
    });
  };

  parseXML = async (file: File) => {
    console.log('reading input file:');
    try {
      const bufferArray = await this.readFileAsArrayBuffer(file);
      const workbook = XLSX.read(bufferArray, { type: 'array' });

      let data: Array<collectionType> = [];
      let errors: Array<string> = [];

      const sheets = workbook.SheetNames;
      for (const sheetName of sheets) {
        const sheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json<CollectionXLSX>(sheet);
        json.forEach((res, index) => {
          try {

            const importRes: CollectionXLSX = res;
            const collectionT: collectionType = {
              idArk: importRes.arkId,
              label: importRes.label,
              mother: importRes.mother,
              parentIdArk: importRes.parentIdArk,
              parentTrigramme: importRes.parentTrigramme,
              acronym: importRes.trigramme
            };
            if (!collectionT.idArk || !collectionT.label || collectionT.mother == undefined || !collectionT.acronym) {
              errors.push(`Error on line ${index}: ${JSON.stringify(collectionT)}`);
            } else {
              data.push(collectionT);
            }

          } catch (err) {
            errors.push(`Error on line ${index}: ${err}`);
          }
        });
      }

      if (data.length > 0) {
        console.log("Data parsed successfully:", { data, success: true, errors });
        return { data, success: true, errors };
      } else {
        console.log("No valid data found.", { errors, success: false });
        return { errors, success: false };
      }
    } catch (err) {
      console.error("Error processing file:", err);
      return { data: err, success: false };
    }
  };
}