/**
 * @class Defines a minio.
 */

export interface Minioitem {
  id: number;
  label: string;
  link: string;
}


export interface MinioType {
  prefix: string;
  size: number;
  items: Minioitem[];
}

export class MinioModel {
  name: string;
  items: Minioitem[];

  constructor(bo: MinioType, array:Minioitem[], prefix:string) {
    const segments = bo.prefix.split('/');
    this.name = segments[segments.length - 2]; // Assuming the second-to-last part is the date
    this.items = [];
    let num = array.length;
    let type = segments[2];
    
    if(type == 'monthly' && this.name != "last")
      num--

    for(let i=0; i < num; i++){
      let _n = '';
      if(this.name != "last")
        _n = this.name + '.'; 
      _n += prefix + array[i].link;
      let newType = {id:array[i].id, label:array[i].label, link:_n};
      this.items.push(newType);
    }
  }
}