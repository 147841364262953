import React, {useState, useEffect, useCallback } from "react";
import "./collections.scss";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import InputText from "../../components/inputText/inputText";
import Checkbox from "../../components/checkbox/checkbox";
import { ColumnDef, Row } from "@tanstack/react-table";
import { CollectionModel, collectionType } from "../../models/collection.model";
import CollectionService from "../../services/collections.service";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch } from "react-redux";

interface Props { }

const NewCollection = (props: Props) => {
  const [title, setTitle] = useState<string>("Nouvelle collection");
  const [idArk, setIdArk] = useState<string>("");
  const [parentIdArk, setParentIdArk] = useState<string>("");
  const [parentTrigramme, setParentTri] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [acronym, setAcronym] = useState<string>("");
  const [isMother, setIsMother] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [collections, setCollections] = useState<collectionType[]>([]);

  const collectionsService = new CollectionService();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedCollections, setSelectedCollections] = useState<
    collectionType[]
  >([]);

  useEffect(() => {
    if (location.state?.update) {
      setIsUpdate(true);
      const collectionToUpdate: collectionType = location.state.update;

      setIdArk(collectionToUpdate.idArk);
      setLabel(collectionToUpdate.label);
      setAcronym(collectionToUpdate.acronym);
      setIsMother(collectionToUpdate.mother);
      setParentIdArk(collectionToUpdate.parentIdArk);
      setParentTri(collectionToUpdate.parentTrigramme);
      setTitle("Modification de la collection");

    }
    search();
  }, [location.state]);

  const search = () => {
    const filtered = undefined;
    collectionsService.getCollections(1000, 1, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data && res.data.docs) {
        setCollections(res.data.docs.filter((doc: any) => doc.mother === true));
      }
    });
  };

  useEffect(() => {
    setIsCompleted(idArk !== "" && label !== "" && acronym !== "");
  }, [idArk, label, acronym]);

  const feedback = (res: { success: boolean; data: any }) => {
    const toastData: ToastType = {
      title: res.success ? "Collection enregistrée avec succès" : "Erreur",
      texts: [{ text: res.success ? "" : res.data }],
      status: res.success ? "success" : "error",
      position: "bottomRight",
      autoClose: res.success,
    };
    toastUtil.show(toastData, dispatch);
    if (res.success) navigate("/collections");
  };

  const updateSelection = useCallback(
    (collection: collectionType, checked: boolean) => {
      if (selectedCollections.includes(collection) || !checked) {
        // Deselect if the collection is already selected
        setSelectedCollections([]);
        setParentIdArk("");
        setParentTri("");
      } else {
        // Select only the clicked collection
        setSelectedCollections([collection]);
        setParentIdArk(collection.idArk);
        setParentTri(collection.acronym);
      }
    },
    [selectedCollections]
  );

  const onSave = () => {

    const collectionValues: collectionType = {
      idArk,
      label: label.replace("&", "et"),
      mother: isMother,
      acronym,
      parentIdArk: parentIdArk,
      parentTrigramme: parentTrigramme,
    };

    const colModel = new CollectionModel(collectionValues);

    collectionsService[isUpdate ? "updateCollection" : "createCollection"](
      colModel
    ).then((res) => feedback(res));
  };

  const onSelect = (ifItisMother: boolean) => {
    setIsMother(ifItisMother)

    if (ifItisMother) {
      setSelectedCollections([]);
      setParentIdArk("");
      setParentTri("");
    }
  };

  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>
        <SecondaryLink
          label="Annuler"
          to="/collections"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full max-w-xl gap-y-4 rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <small className="text-indigo-700">* Tous les champs sont requis</small>
        <InputText
          type="text"
          label="Label"
          id="label"
          classname="w-full"
          value={label}
          onChange={(e: any) => setLabel(e.target.value)}
        />
        <div className="w-full flex gap-4 justify-center items-center">
          <InputText
            type="text"
            label="ark ID"
            id="idArk"
            placeholder="ark:/86527/..."
            classname="w-1/2"
            value={idArk}
            onChange={(e: any) => setIdArk(e.target.value)}
          />
          <InputText
            type="text"
            label="Trigramme"
            id="acronym"
            classname="w-1/2"
            value={acronym}
            onChange={(e: any) => setAcronym(e.target.value)}
          />
        </div>
        <div>
          <Checkbox
            id="mother"
            classname="font-medium self-end inline-block"
            label="Collection mère"
            checked={isMother}
            onChange={(e) => onSelect(e.target.checked)}
          />
          <div className="font-medium self-end inline-block" style={{ width: '120px' }}></div>
          <Checkbox
            id="fille"
            classname="font-medium self-end inline-block"
            label="Collection fille"
            checked={!isMother}
            onChange={(e) => onSelect(!e.target.checked)}
          />
        </div>
        {!isMother && (
          <div>
            <ul
              role="list"
              className="max-h-96 overflow-y-auto niceScroll mx-6 divide-y divide-blue-200 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
            >
              {collections.map((collection: collectionType) => {
                return (
                  <li
                    className={
                      "w-full flex justify-between py-3 px-5 cursor-pointer " +
                      (collection.mother ? "text-indigo-700 " : "") +
                      (selectedCollections.includes(collection)
                        ? "bg-indigo-100"
                        : "") +
                      " hover:bg-indigo-50"
                    }
                    key={collection.idArk}
                  >
                    {collection.label}
                    <Checkbox
                    key={collection.idArk}
                      id={collection.idArk}
                      label=""
                      onChange={(e:React.ChangeEvent<HTMLInputElement>) => updateSelection(collection, e.target.checked)}
                      checked={selectedCollections.includes(collection) || collection.idArk == parentIdArk}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={!isCompleted}
        />
      </form>
    </div>
  );
};

export default NewCollection;
