import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";

export default class MinioService extends BaseService {
  constructor() {
    super();
  }

  getMinio = async (subpath: string, folder:boolean = true) => {
    const url = new URL(window.location.href); // Current page URL
    const params = new URLSearchParams(url.search); // Extract query parameters

    const name = params.get("name") || "";
    if(name == ""){
      return { data: [], success: false };
    }
    let path = name + '/' + subpath;
    let _default = 'minio';

    if(!folder)
      _default = 'minioobj'  
    
      return await this.axios
        .get(`${this.apiUrl}/api/` + _default, {
          headers: this.getHeader(),
          params: { 'path': path },
        })
        .then(async (res: { status: StatusCodes; data: any }) => {
          if (
            [
              StatusCodes.CREATED,
              StatusCodes.NO_CONTENT,
              StatusCodes.ACCEPTED,
              StatusCodes.OK,
            ].includes(res.status)
          ) {
            return {
              data: res.data,
              success: true,
            };
          }

          return { data: res.data, success: false };
        })
        .catch((error: any) => {
          return {
            data:
              "Code " +
              error.response.status +
              " : " +
              error.response.data?.error,
            success: false,
            redirect: this.checkError(error.response.status),
          };
        });
  };
}
