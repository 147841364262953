/**
 * @class Defines a collection.
 */

export interface MapType {
  name:string;
  enterprises: string[];
  type: string;
}

export class MapModel {
  name:string;
  enterprises: string[];
  type: string;

  constructor(bo: MapType) {
    this.name = bo.name;
    this.enterprises = bo.enterprises;
    this.type= bo.type;
  }
}
