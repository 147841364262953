import React, { useEffect, useMemo, useState } from "react";
import {
  PrimaryButton,
  PrimaryLink,
  TertiaryButton,
} from "../../components/buttons/buttons";
import { PlusIcon } from "@heroicons/react/24/outline";
import SearchBar, { InputType } from "../../components/searchBar/searchBar";
import MapsService from "../../services/maps.service";
import Table from "../../components/table/table";
import { ColumnDef, Row } from "@tanstack/react-table";
import { ToastType } from "../../parts/toast/toast";
import { useDispatch } from "react-redux";
import toastUtil from "../../utils/toast.util";
import { closeToast } from "../../store/toast.slice";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "../../components/pagination/paginate.scss";
import "./maps.scss";
import { PaginationModel } from "../../models/pagination.model";
import ReactPaginate from "react-paginate";
import DotsSpinner from "../../components/dotsSpinner/dotsSpinner";
import { MapType } from "../../models/map.model";

const mapsService = new MapsService();

const Maps = () => {
  const [searching, setSearching] = useState<boolean>(false);
  const [reload, setReaload] = useState<boolean>(true);
  const [userRole, setUserRole] = useState<string>("");
  const [userEnterprise, setUserEnterprise] = useState<string>("");
  const [maps, setMaps] = useState<MapType[]>([]);
  const [textFilter, setTextFilter] = useState<string>("");
  const [actions, setActions] = useState<any>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const limit: number = 10;

  const [mapPagination, setPagination] = useState<PaginationModel>({});

  const user = localStorage.getItem("currentUser");

  const mapSearchInputs: InputType[] = [
    { name: "Nom", type: "text", onChange: setTextFilter },
  ];


  useEffect(() => {
    if (user) {
      setUserRole(JSON.parse(user).role);
      setUserEnterprise(JSON.parse(user).enterprise);

      if (userRole === "admin") {
        setActions({
          update: updateMap,
          delete: validateDeletion,
          visit: visitMap
        })
      } else {
        setActions({
          visit: visitMap
        })
      }

    }
  }, [userRole, userEnterprise]);


  const search = (page: number) => {
    setSearching(true);
    const filtered = textFilter || undefined;
    mapsService.getMaps(limit, page, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data.docs) {
        setMaps(res.data.docs);
        setPagination(res.data);
        setReaload(false);
      }
      setSearching(false);
    });
  };

  const handlePageClick = (event: { selected: number }) => {
    search(event.selected + 1);
  };

  const filter = () => {
    search(0);
  };

  useEffect(() => {
    if (reload)
      search(0);
  }, [reload]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "enterprises",
        header: "Enterprises",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "type",
        header: "Type",
        sortingFn: "alphanumeric",
      }
    ],
    []
  );

  const updateMap = (map: MapType) => {
    navigate("/newMap", { state: { update: map } });
  };


  const visitMap = (map: MapType) => {
    navigate("/map?name=" + map.name, {});
  };

  const validateDeletion = (map: MapType) => {
    const toastData: ToastType = {
      title: "Suppression",
      texts: [
        {
          text:
            "Voulez-vous supprimer la collection " + map.name + " ?",
        },
      ],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: [
        <TertiaryButton
          key="no"
          label="Non"
          onClick={() => dispatch(closeToast())}
          textClassname="text-sm px-6 py-2"
        />,
        <div key="yes" onClick={() => dispatch(closeToast())}>
          <PrimaryButton
            label="Oui"
            onClick={() => deleteMap(map.name)}
            textClassname="text-sm"
          />
        </div>,
      ],
    };
    toastUtil.show(toastData, dispatch);
  };

  const deleteMap = (link: string = "") => {
    mapsService.deleteMap(link).then((res: any) => {
      const toastData: ToastType = {
        title: res.success ? "Map supprimée" : "Erreur",
        texts: [{ text: res.success ? "" : res.data }],
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);

      setReaload(true);
    });
  };


  const returnMap = (maps: any) => {

    if (userRole == "admin") {
      return maps;
    }

    for (let map of maps) {
      if (map.enterprises.includes(userEnterprise))
        map['disabled'] = false;
      else
        map['disabled'] = true;
    }
    return maps;
  }



  return (
    <div className="flex flex-col maps">
      <div className="flex justify-between items-center">
        <h2 className="title">Liste des maps</h2>
        {userRole == "admin" && <div className="buttonsBlock">
          <PrimaryLink
            label="Ajouter une map"
            to="/newMap"
            btnClassname="addButton"
            textClassname="text-sm"
            icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>}
      </div>
      <SearchBar inputs={mapSearchInputs} action={filter} />
      {searching && <DotsSpinner className="centered" />}
      {!searching && maps.length < 1 &&
        <div className="mt-8 p-3 mx-auto text-md">
          Aucun résultat
        </div>
      }
      {maps.length > 0 &&
        <>
          <Table
            columns={columns}
            data={returnMap(maps)}
            actions={actions}
            cellStyle="min-w-32"
          />

          <ReactPaginate
            nextLabel={
              <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 hover:bg-gray-100">
                <ChevronRightIcon className="w-5 h-5" />
              </span>
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            breakLabel="..."
            pageCount={mapPagination.totalPages || 0}
            previousLabel={
              <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 mr-4 hover:bg-gray-100">
                <ChevronLeftIcon className="w-5 h-5" />
              </span>
            }
            renderOnZeroPageCount={null}
            containerClassName="flex items-center justify-center mt-8 mb-4"
            pageClassName="btnPage block border border-solid border-gray-200 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded mr-4"
            breakClassName="mr-4"
            activeClassName="bg-indigo-600 text-white hover:bg-indigo-600"
          />
        </>
      }
    </div>
  );
};

export default Maps;
