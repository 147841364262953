import {
  SubscriptionModel,
  subscriptionType,
} from "../models/subsrcription.model";
import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";

/// 0019395A (mères) | 9999999P (fille)
export default class SubscriptionsService extends BaseService {
  constructor() {
    super();
  }

  createSubscription = async (xmlValues: subscriptionType) => {
    if (!(xmlValues instanceof SubscriptionModel)) {
      throw new TypeError(
        'The subscription must be an instance of class "SubscriptionModel"'
      );
    }

    return await this.axios
      .post(`${this.apiUrl}/api/subscription/`, JSON.stringify(xmlValues), {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        console.log("error", error)
        return {
          data: `Code ${error.response.data.code} : ${error.response.data?.error}`,
          details: error.response.data?.data,
          success: false,
        };
      });
  };

  updateSubscription = async (values: subscriptionType) => {
    if (!(values instanceof SubscriptionModel)) {
      throw new TypeError(
        'The subscription must be an instance of class "SubscriptionModel"'
      );
    }

    return await this.axios
      .put(`${this.apiUrl}/api/subscription/`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          details: error.response.data?.data,
          success: false,
        };
      });
  };

  deleteSubscription = async (idAbonnement: string) => {
    return await this.axios
      .delete(`${this.apiUrl}/api/subscription/`, {
        headers: this.getHeader(),
        data: {
          idAbonnement: idAbonnement,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,

          details: error.response.data?.data,
          success: false,
        };
      });
  };

  getSubscriptions = async (values: {
    uaiEtab?: string;
    idRessource?: string;
  }) => {
    if (values.uaiEtab || values.idRessource) {
      return await this.axios
        .get(`${this.apiUrl}/api/subscriptions/`, {
          params: values,
          headers: this.getHeader(),
        })
        .then(async (res: { status: StatusCodes; data: any }) => {
          if (StatusCodes.OK === res.status) {
            return { success: true, data: res?.data.data };
          }
          return { success: false, data: res?.data.error };
        })
        .catch((error: any) => {
          return {
            data:
              "Code " +
              error.response.status +
              " : " +
              error.response.data?.error,
            success: false,
            redirect: this.checkError(error.response.status),
          };
        });
    }
  };

  createSubscriptions = async (subscriptions: Array<subscriptionType>) => {
    return await this.axios
      .post(
        `${this.apiUrl}/api/subscriptions/`,
        JSON.stringify(subscriptions),
        {
          headers: this.getHeader(),
        }
      )
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          details: error.response.data?.data,
          success: false,
        };
      });
  };
}
